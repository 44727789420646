import React from 'react';

const Schedule = () => {
	return ( 
		<div className="schedule">
			// do shit here
			</div>
	 );
}
 
export default Schedule;